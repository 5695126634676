import {
  CheckboxIndicator,
  CheckboxProps,
  Checkbox as CheckboxRoot,
} from '@radix-ui/react-checkbox';
import { clsx } from 'clsx';
import { forwardRef } from 'react';
import { CheckIcon } from '../icons/react/check.tsx';
import { checkbox, checkboxIndicator } from './checkbox.css.ts';

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  function CheckboxComponent({ children, className, ...rest }, ref) {
    return (
      <CheckboxRoot ref={ref} className={clsx(className, checkbox)} {...rest}>
        <CheckboxIndicator className={checkboxIndicator}>
          <CheckIcon width={18} height={18} />
        </CheckboxIndicator>
      </CheckboxRoot>
    );
  },
);
